@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
body {
  background-color: #f3f4f6;
  font-family: "Inter", sans-serif;
}
@media (prefers-color-scheme: dark) {
  body {
    background-color: #1e293b;
  }
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #d1d5db;
  border-radius: 10px;
}

.scrollbar-track-rounded-full::-webkit-scrollbar-track {
  border-radius: 10px;
}
.scrollbar-thumb-rounded-full::-webkit-scrollbar-track {
  border-radius: 10px;
}
